<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        ref="refSubscriptionListTable"
        class="position-relative"
        :items="subscriptions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <template #cell(package)="data">
          {{ data.item.package.name }}
        </template>

        <template #cell(plan)="data">
          <span>{{ data.item.packageplan.title }}</span>
          <br>
          <small class="text-muted">
            {{ data.item.packageplan.subTitle }}
          </small>
        </template>

        <template #cell(subscribed_by)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.CreatedBy.name)"
                variant="light-primary"
                style="cursor: pointer"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              :to="{ name: 'system-settings-users-view', params: { id: data.item.CreatedBy.id } }"
            >
              <span class="text-capitalize">{{ data.item.CreatedBy.name }}</span>
            </b-link>
            <small class="text-muted text-capitalize">{{ data.item.CreatedBy.phone }}</small>
          </b-media>
        </template>

        <!-- Column: Payment Method -->
        <template #cell(PaymentMethod)="data">
          {{ resolvePaymentMethod(data.item.PaymentMethod) }}
        </template>

        <!-- Column: Payment Status -->
        <template #cell(payment_status)="data">
          {{ resolvePaymentStatus(data.item.PaymentStatus) }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveSStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveSStatus(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(download)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
            :to="{ name: 'sales-subscriptions-invoice', params: { id: data.item.id } }"
          >
            <span class="text-capitalize">Download</span>
          </b-link>
        </template>

        <template #cell(expiry_date)="data">
          <span class="text-danger">{{ formatDate(data.item.ExpiryDate) }}</span>
        </template>

        <template #cell(subscription_date)="data">
          {{ formatDate(data.item.StartDate) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('E', 'Retailers & Branches')"
              :to="{ name: 'retailers-management-retailer-subscriptions-edit', params: { id: data.item.SubscriptionId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
import useSubscriptionsList from './useSubscriptionsList'
import {
  resolveSStatus,
  resolveSStatusVariant,
  resolvePaymentMethod,
  resolvePaymentStatus,
} from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      tableColumns,
    } = useSubscriptionsList()

    return {
      tableColumns,

      resolveSStatus,
      resolveSStatusVariant,
      resolvePaymentMethod,
      resolvePaymentStatus,
      avatarText,
      formatDate,
    }
  },
}
</script>
