import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useRetailersForm() {
  const retailerData = ref({})

  const unsetVatID = () => {
    if (!retailerData.value.is_taxable) {
      retailerData.value.TaxID = null
    }
  }

  const fetchRetailer = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchRetailer', params)
      // eslint-disable-next-line prefer-destructuring
      retailerData.value = response.data.data[0]
      return response
    } catch (error) {
      retailerData.value = undefined
      return error
    }
  }

  const updateRetailer = async () => {
    try {
      const response = await store.dispatch('app-retailer/updateRetailer', retailerData.value)

      if (!response.data.status) {
        return false
      }
      router.push({ name: 'retailers-management-retailers-list' })
      return response
    } catch (error) {
      return error
    }
  }

  return {
    retailerData,

    fetchRetailer,
    updateRetailer,
    unsetVatID,
  }
}
