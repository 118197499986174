<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="retailerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching retailer data
      </h4>
      <div class="alert-body">
        No retailer found with this retailer id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'retailers-management-retailers-list'}"
        >
          Retailer List
        </b-link>
        for other retailers.
      </div>
    </b-alert>

    <template v-if="retailerData">
      <!-- Retailer Info -->
      <b-row>
        <b-col
          cols="12"
        >
          <retailer-view-info-card :retailer-data="retailerData" />
        </b-col>
      </b-row>

      <!-- Branches -->
      <b-card
        v-if="branchesData && branchesData.length > 0"
        no-body
      >
        <b-row>
          <b-col class="mt-2">
            <h4 class="px-2 mb-2">
              Branches
            </h4>
            <branches-list
              :table-columns="tableColumns"
              :branches="branchesData"
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- Subscription -->
      <b-card
        v-if="retailerData.subscriptions && retailerData.subscriptions.length > 0"
        no-body
      >
        <b-row>
          <b-col class="mt-2">

            <h4 class="px-2 mb-2">
              Subscriptions
            </h4>

            <subscriptions-list :subscriptions="retailerData.subscriptions" />

          </b-col>
        </b-row>
      </b-card>
    </template>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import retailerStoreModule from '../retailerStoreModule'
import RetailerViewInfoCard from './RetailerViewInfoCard.vue'
import useRetailersForm from '../retailers-form/useRetailersForm'
import BranchesList from '../branches/branches-list/BranchesList.vue'
import useBranchesForm from '../branches/branches-form/useBranchesForm'
import SubscriptionsList from '../subscriptions/subscriptions-list/SubscriptionsList.vue'
import useBranchesList from '../branches/branches-list/useBranchesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    RetailerViewInfoCard,
    BranchesList,
    SubscriptionsList,
  },
  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const {
      retailerData,

      fetchRetailer,
    } = useRetailersForm()

    const {
      branchesData,

      fetchBranches,
    } = useBranchesForm()

    const { tableColumns } = useBranchesList()

    fetchRetailer(router.currentRoute.params.id)

    fetchBranches(router.currentRoute.params.id)

    return {
      tableColumns,
      retailerData,
      branchesData,
    }
  },
}
</script>
