import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useSubscriptionsList() {
  // Table Handlers
  const tableColumns = [
    { key: 'actions' },
    { key: 'package', sortable: true },
    { key: 'plan', sortable: true },
    { key: 'subscription_date', sortable: false },
    { key: 'expiry_date', sortable: false },
    { key: 'PaymentMethod' },
    { key: 'payment_status' },
    { key: 'status', sortable: false },
    { key: 'subscribed_by', sortable: false },
    { key: 'download', sortable: false },
  ]

  const { callPaymentMethods, callPaymentStatus } = useSystemCodes()
  callPaymentMethods()
  callPaymentStatus()

  return {
    tableColumns,
  }
}
