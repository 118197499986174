<template>
  <b-card no-body>
    <b-row class="px-2 pt-2">
      <b-col sm="8">
        <h4>
          {{ retailerData.BusinessName }}
        </h4>
        <b-badge
          v-if="retailerData.status"
          pill
          :variant="`light-${resolveStatusVariant(retailerData.status)}`"
          class="text-capitalize"
        >
          {{ resolveStatus(retailerData.status) }}
        </b-badge>
      </b-col>

      <b-col sm="4">
        <b-button
          v-if="$ability.can('E', 'Retailers & Branches') && retailerData.entityId"
          size="sm"
          :to="{ name: 'retailers-management-retailers-edit', params: { id: retailerData.entityId } }"
          variant="primary"
          class="float-right"
        >
          Edit
        </b-button>
      </b-col>
    </b-row>

    <b-row class="px-1">
      <b-col sm="12">
        <!-- <b-list-group flush>
          <b-list-group-item
            v-for="(item, key) in retailerData"
            :key="key"
            class="border-0"
          >
            <b-row v-if="key !== 'entityId' && key !== 'status' && key !== 'subscription'">
              <b-col sm="8">
                <strong>{{ key }}</strong>
              </b-col>
              <b-col sm="4">
                {{ item }}
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group> -->

        <b-list-group
          v-if="retailerData.BusinessName"
          flush
        >

          <!-- CR Number -->
          <b-list-group-item
            v-if="retailerData.CRNumber"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>CR Number</strong>
              </b-col>
              <b-col sm="4">
                {{ retailerData.CRNumber }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Is Taxable -->
          <b-list-group-item
            v-if="!retailerData.is_taxable"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Is Taxable</strong>
              </b-col>
              <b-col sm="4">
                {{ retailerData.is_taxable ? 'Yes' : 'No' }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- VAT ID -->
          <b-list-group-item
            v-else
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>VAT Id</strong>
              </b-col>
              <b-col sm="4">
                {{ retailerData.TaxID }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- ZERO VAT -->
          <b-list-group-item class="border-0">
            <b-row>
              <b-col sm="8">
                <strong>Zero VAT</strong>
              </b-col>
              <b-col
                class="text-capitalize"
                sm="4"
              >
                {{ retailerData.ZeroVat ? 'Yes' : 'No' }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Owner Name -->
          <b-list-group-item
            v-if="retailerData.OwnerName"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Owner Name</strong>
              </b-col>
              <b-col sm="4">
                <span class="text-capitalize">{{ retailerData.OwnerName }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Owner Mobile -->
          <b-list-group-item
            v-if="retailerData.OwnerMobile"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Owner Mobile</strong>
              </b-col>
              <b-col sm="4">
                {{ retailerData.OwnerMobile }}
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BBadge, BButton,
} from 'bootstrap-vue'
// import useRetailersList from '../retailers-list/useRetailersList'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
  },
  props: {
    retailerData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const {
    //   resolveStatus,
    //   resolveStatusVariant,
    // } = useRetailersList()

    return {
      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>
